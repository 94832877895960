import React from 'react'
import Sidebar from '../Sidebar'

import { Dashboard } from "./Dashboard";
import { Route , Routes , useLocation } from 'react-router-dom';
import  {Courses}  from './Courses';
// import Course from "./Courses"
import CreateCourse from './CreateCourse';
import CreateSection from './CreateSection';
import CreateVideo from './CreateVideo';
import Enrolls from './Enrolls';
import Students from './Students';
import Login from './Login';
import QuizComponent from './createQuiz';
import Navbar from '../Navbar';
import GetQuiz from './getQuiz';
import Quizzes from './quizzes';


function Layout() {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';

  return (
    <div className='d-flex justify-content-start h-100'>
      {!isLoginRoute && <Sidebar />}
      <div className='contntArea flex-grow-1'>
      {!isLoginRoute && <Navbar />}
        <div className={`w-100 ${!isLoginRoute ? "layer p-4": "" } `}>
      <Routes>
{/* <Route path="/" element={<Home />} />
        <Route path="/course/:id" element={<SingleCourse />} />
        <Route path="/register" element={<Register />} /> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/sections" element={<CreateSection/>} />
        <Route path="/vedioes" element={<CreateVideo/>} />
        <Route path="/quiz/add" element={<QuizComponent />} />
        <Route path="/quizzes" element={<Quizzes />} />
        
        <Route path="/scores/:id" element={<GetQuiz />} />
        <Route path="/enrolls" element={<Enrolls/>} />
        <Route path="/students" element={<Students/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/courses/add" element={<CreateCourse/>} />
        {/* <Route path="/quiz/add" element={<CreateCourse/>} /> */}

</Routes>
</div>
      </div>
    
    </div>
  )
}

export default Layout
