import React, { useState, useEffect, useCallback , useRef } from "react";
import "./Dashboard.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CDBDataTable } from "cdbreact";
import { toast } from "react-toastify";
import PaginationComponent from "../componentes/Pagination";
function CreateVideo() {
  const navigate = useNavigate();
  // Check if there's a token in local storage
  const token = localStorage.getItem("token");
  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }

  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [vedioes, setVedioes] = useState([]);
  const [sections, setSections] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState("");
  // const [loaded, setLoaded] = useState(0);
  let progressRef = useRef(0); // Use a ref to keep track of progress
  const handleDeleteVedio = async (id) => {
    try {
      // Submit the form data
      await axios.delete(`https://lms.gamal-abdelnasser.com/vedioes/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      });
      toast.success("تم المسح");
      getAllVedioes();
      // console.log("Form submitted successfully:", values);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message);
    }
  };

  const getAllVedioes = useCallback(async () => {
    try {
      const response = await axios.get("https://lms.gamal-abdelnasser.com/vedioes", {
        params: { page, pageSize, search },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      });
      setVedioes(response.data.data.videos);
      setTotalRecords(response.data.data.totalRecords);
      // Handle other response data like totalRecords, etc.
    } catch (error) {
      console.error("Error fetching enrollments:", error);
    }
  }, [page, pageSize, search]);

  useEffect(() => {
    axios
      .get("https://lms.gamal-abdelnasser.com/sections", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      })
      .then((response) => {
        setSections(response.data.data); // Set the fetched courses to the state
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
    // Fetch courses from the backend endpoint
    getAllVedioes();
  }, [getAllVedioes]);

 

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page on search
  };

  const totalPages = Math.ceil(totalRecords / pageSize);


  const schema = yup.object().shape({
    title: yup.string().required("عنوان الفيديو مطلوب"),
    sectionId: yup.string().required("الوحدة مطلوبة"),
    video: yup.mixed().required("الفيديو مطلوب"),
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVideoFile(file);
    const videoURL = URL.createObjectURL(file);
    setVideoPreview(videoURL);

    // Extract video duration
    const videoElement = document.createElement("video");
    videoElement.src = videoURL;
    videoElement.onloadedmetadata = () => {
      setVideoDuration(videoElement.duration);
    };
  };

  const chunkSize = 1024 * 1024 * 10; // 20MB chunk size

  const handleSubmit = async (values, { resetForm, setFieldValue }) => {
    const totalChunks = Math.ceil(videoFile.size / chunkSize);
    console.log(totalChunks);
    setUploadPercentage(.1);
    let uploadSuccessful = true; // Flag to track the success of the upload

    for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, videoFile.size);
        const chunk = videoFile.slice(start, end);

        console.log(values.sectionId);

        const formData = new FormData();
        formData.append("originalFileName", videoFile.name);
        formData.append("chunkIndex", i);
        formData.append("totalChunks", totalChunks);
        formData.append("sectionId", values.sectionId);
        formData.append("title", values.title);
        formData.append("duration", videoDuration); // Append video duration
        formData.append("chunk", chunk);
 
        try {
            await uploadChunk(formData , totalChunks);
            let overallPercentage = ((i+1) / totalChunks) * 100;
            setUploadPercentage(overallPercentage)
           
            
        } catch (error) {
            console.error(`Error uploading chunk ${i}:`, error.message);
            toast.error(error.message);
            uploadSuccessful = false; // Set the flag to false if any chunk fails
            break; // Stop processing if an error occurs
        }
    }

    resetForm();
    setVideoFile(null);
    setVideoPreview(null);
    setVideoDuration(0);
    let videoInput = document.getElementById("validationFormik06");
    console.log(videoInput.value);
    videoInput.value = "";
    setFieldValue("video", "");

    if (uploadSuccessful) {
      toast.success("تم انشاء الفيديو بنجاح ");
      getAllVedioes();
  }
    // Reset upload progress after 3 seconds
    setTimeout(() => {
      setUploadPercentage(0);
      progressRef.current = 0;
    }, 1000);
};

  async function uploadChunk(formData , totalChunks) {
    console.log(formData.get('sectionId'));
    const response = await axios.post(
      "https://lms.gamal-abdelnasser.com/vedioes/chunk",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
           progressRef.current+= progressEvent.loaded
        }
     
      }
    );
    if (response.status !== 200) {
      throw new Error("Chunk upload failed");
    }
  }

  const columns = [
    { label: "#", field: "index" },
    { label: "الاسم", field: "title" },
    { label: "الوحدة", field: "sectionTitle" },
    {
      label: "اعدادات",
      field: "actions",
    },
  ];

  const rows = vedioes.map((video, index) => ({
    index: index + 1,
    title: video.title,
    sectionTitle: `${video?.section?.course?.title} / ${video?.section?.title}`,
    actions: (
      <div>
        <button
          className="btn btn-danger btn-sm m-auto ms-2 mt-2"
          onClick={() => handleDeleteVedio(video.id)}
        >
          حذف
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <div className="form-container border-bottom pb-3">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            title: "",
            sectionId: "",
            video: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="10"
                  controlId="validationFormik01"
                  className="mb-3"
                >
                  <Form.Label>عنوان الفيديو</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    isValid={touched.title && !errors.title}
                    isInvalid={touched.title && !!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="10"
                  controlId="validationFormik03"
                  className="mb-3"
                >
                  <Form.Label>تابع لوحدة</Form.Label>
                  <Form.Control
                    as="select"
                    name="sectionId"
                    value={values.sectionId}
                    onChange={handleChange}
                    isValid={touched.sectionId && !errors.sectionId}
                    isInvalid={touched.sectionId && !!errors.sectionId}
                  >
                    <option value="">اختر الوحدة...</option>
                    {sections.map((section) => (
                      <option
                        key={section.id}
                        className="text-sm"
                        value={section.id}
                      >
                        {section.course.title} / {section.title}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.sectionId}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="10"
                  controlId="validationFormik06"
                  className="mb-3"
                >
                  <Form.Label>الفيديو </Form.Label>
                  <Form.Control
                    type="file"
                    name="video"
                    accept="video/*"
                    placeholder="اختر ملف الفيديو..."
                    onChange={(e) => {
                      handleFileChange(e);
                      setFieldValue("video", e.target.files[0]);
                    }}
                    isValid={touched.video && !errors.video}
                    isInvalid={touched.video && !!errors.video}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.video}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {uploadPercentage > 0 && (
                <>
                  <div className="progress w-75 m-0 me-3  d-inline-block ">
                    <div
                      className="progress-bar h-100"
                      role="progressbar"
                      style={{ width: `${uploadPercentage.toFixed(2)}%` }}
                    ></div>
                  </div>
                  <span className="text-sm d-inline-block mb-3">{uploadPercentage.toFixed(2)}%</span>
                  {/* <span className="text-sm d-inline-block mb-3">{loaded}/{videoFile?.size}</span> */}
                </>
              )}
              <Button type="submit" className="px-5 w-200px">
                اضافة
              </Button>
            </Form>
          )}
        </Formik>

        <div className="d-flex justify-content-center align-items-center video-placeholder rounded-4">
          {videoPreview && (
            <video className="w-100 h-100 rounded-4" controls>
              <source src={videoPreview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>

      <div className="mt-3">
        <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
          <h4>جميع الفيديوهات </h4>
          <input
            type="text"
            placeholder="ابحث باسم الكورس"
            value={search}
            onChange={handleSearchChange}
            className="form-control saerch-input w-25"
          />
        </div>
        <CDBDataTable
          striped
          bordered
          hover
          data={{ columns, rows }}
          paging={false}
          searching={false}
          checkbox
          sortable={true}
        />
        <PaginationComponent
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default CreateVideo;
