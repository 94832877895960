import { CDBDataTable } from "cdbreact";
import React, { useEffect, useState } from "react";
import PaginationComponent from "../componentes/Pagination";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function Quizzes() {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [quizzes, setQuizzes] = useState([]);

  const [search, setSearch] = useState("");
  const columns = [
    { label: "#", field: "index" },
    { label: "العنوان", field: "title" },
    { label: "الدرجة النهائية", field: "fullMark" },

    { label: "نسبة النجاح  ", field: "passPercent" },

    { label: "اعدادات", field: "actions" },
  ];

  const data = quizzes?.map((quiz, index) => ({
    index: index + 1,
    title: quiz.title,

    fullMark: quiz?.Questions?.length,
    passPercent: <>{quiz.passPercent} %</>,
    actions: (
      <div>
        <Link
          to={`/scores/${quiz.id}`}
          className="btn btn-warning btn-sm border-0 ml-auto text-sm"
        >
          عرض الدرجات
        </Link>
        <button
          className="btn btn-danger btn-sm m-auto ms-2 "
          onClick={() => handleDeleteQuiz(quiz.id)}
        >
          حذف
        </button>
      </div>
    ),
  }));

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page on search
  };

  const handleDeleteQuiz = async(id)=>{
    try {
      // Submit the form data
      await axios.delete(`https://lms.gamal-abdelnasser.com/quizzes/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      });
      toast.success("تم المسح");
      // getAl();
      // console.log("Form submitted successfully:", values);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.response.data.message);
    }
  }

  useEffect(() => {
    axios
      .get("https://lms.gamal-abdelnasser.com/quizzes", {
        params: { page, pageSize, search },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      })
      .then((response) => {
        setQuizzes(response.data.data.Quizzes);
        setTotalRecords(response.data.data.totalRecords);
      })
      .catch((error) => {
        console.error("Error fetching quizzes:", error);
      });
  }, [page, pageSize, search]);

  const totalPages = Math.ceil(totalRecords / pageSize);

  return (
    <div>
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <Link
          to="/quiz/add"
          className="btn btn-primary border-0 ml-auto px-3 py-2 my-2 text-white text-sm"
          // style={{ background: "#596FB7" }}
        >
          <i className="fa fa-plus me-2"></i>
          اضافة كويز
        </Link>
        <input
          type="text"
          placeholder="ابحث باسم الكويز  "
          value={search}
          onChange={handleSearchChange}
          className="form-control saerch-input w-25"
        />
      </div>
      <CDBDataTable
        striped
        bordered
        hover
        data={{ columns, rows: data }}
        paging={false}
        searching={false}
        checkbox
        sortable={true}
      />
      <PaginationComponent
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default Quizzes;
