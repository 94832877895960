import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
  };

  return (
    <CDBSidebar
      className="text-start sidebar-h "
      textColor="#fff"
      backgroundColor="#102C57"
      maxWidth="250px"
    >
      <CDBSidebarHeader
        className="head-padding"
        prefix={<i className="fa fa-bars fa-large"></i>}
      >
        <a
          href="/"
          className="text-decoration-none"
          style={{ color: "inherit" }}
        >
          البروفيسير ف الكيمياء
        </a>
      </CDBSidebarHeader>

      <CDBSidebarContent className="sidebar-content pt-0">
        <CDBSidebarMenu className="p-0">
          <NavLink exact to="/courses">
            <CDBSidebarMenuItem
              icon="table">الكورسات</CDBSidebarMenuItem>
              
          </NavLink>
          <NavLink exact to="/sections" >
            <CDBSidebarMenuItem icon="fa fa-layer-group">الوحدات</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/vedioes">
            <CDBSidebarMenuItem icon="fa fa-video">الفيديوهات</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/enrolls">
            <CDBSidebarMenuItem icon="fa fa-cash-register">طلبات الاشتراك</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/quizzes">
            <CDBSidebarMenuItem  icon=" fa  fa-file-signature">كويزات</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/students">
            <CDBSidebarMenuItem icon="users">الطلبة</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/login" onClick={handleLogout} className="position-absolute bottom-0 w-100" >
            <CDBSidebarMenuItem className="d-flex align-items-center"
            icon="fa fa-sign-out-alt"
             >تسجيل الخروج</CDBSidebarMenuItem>
              
          </NavLink>
        </CDBSidebarMenu>

      </CDBSidebarContent>
    </CDBSidebar>
  );
};

export default Sidebar;
