import React, { useEffect, useState } from "react";
import { CDBProgress } from "cdbreact";

import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

export const Dashboard = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get("https://lms.gamal-abdelnasser.com/homeData");
        console.log(response);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
        toast.error("حدثت مشكلة حاول مرة اخري ");
      }
    };

    fetchCourses();
  }, []);

  const navigate = useNavigate();

  // Check if there's a token in local storage
  const token = localStorage.getItem("token");

  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }

  return (
    <div className="dashboard w-100">
      <div className="d-flex card-section">
        <div className="cards-container">
          <div className="card-bg w-100 border d-flex flex-column">
            <div className="p-4 d-flex flex-column h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="m-0 h5 font-weight-bold text-dark">كورسات</h4>
                <div className="py-1 px-2 bg-grey rounded-circle">
                  <i className="fas fa-suitcase"></i>
                </div>
              </div>
              <h4 className="my-4 text-right text-dark h2 font-weight-bold">
                {data?.courses}
              </h4>
              <CDBProgress value={60} height={8} colors="primary"></CDBProgress>
              <p className="mt-2 text-success small">
                <i className="fas fa-angle-up p-0"></i>
                <span
                  style={{ fontSize: "0.95em" }}
                  className="ml-2 font-weight-bold text-muted"
                >
                  عدد الكورسات
                </span>
              </p>
              <Link
                to="/courses"
                className="c-p mb-0 text-dark font-weight-bold text-right mt-auto"
              >
                تفاصيل
                <i className="fas fa-arrow-right ml-1"></i>
              </Link>
            </div>
          </div>
          <div className="card-bg w-100 border d-flex flex-column">
            <div className="p-4 d-flex flex-column h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="m-0 h5 font-weight-bold text-dark">فيديوهات</h4>
                <div className="py-1 px-2 bg-grey rounded-circle">
                  <i className="fas fa-suitcase"></i>
                </div>
              </div>
              <h4 className="my-4 text-right text-dark h2 font-weight-bold">
                {data?.vedioes}
              </h4>
              <CDBProgress value={60} height={8} colors="primary"></CDBProgress>
              <p className="mt-2 text-success small">
                <i className="fas fa-angle-up p-0"></i>
                <span
                  style={{ fontSize: "0.95em" }}
                  className="ml-2 font-weight-bold text-muted"
                >
                  عدد الفيديوهات داخل الكورسات
                </span>
              </p>
              <Link
                to="/courses"
                className="c-p mb-0 text-dark font-weight-bold text-right mt-auto"
              >
                تفاصيل
                <i className="fas fa-arrow-right ml-1"></i>
              </Link>
            </div>
          </div>
          <div className="card-bg w-100 border d-flex flex-column">
            <div className="p-4 d-flex flex-column h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="m-0 h5 font-weight-bold text-dark">طلبة</h4>
                <div className="py-1 px-2 bg-grey rounded-circle">
                  <i className="fas fa-suitcase"></i>
                </div>
              </div>
              <h4 className="my-4 text-right text-dark h2 font-weight-bold">
                {data?.Users}
              </h4>
              <CDBProgress value={70} height={8} colors="primary"></CDBProgress>
              <p className="mt-2 text-success small">
                <i className="fas fa-angle-up p-0"></i>
                <span
                  style={{ fontSize: "0.95em" }}
                  className="ml-2 font-weight-bold text-muted"
                >
                  عدد الطلبة المشتركين ف الموقع
                </span>
              </p>
              <Link
                to="/students"
                className="c-p mb-0 text-dark font-weight-bold text-right mt-auto"
              >
                تفاصيل
                <i className="fas fa-arrow-right ml-1"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
