

import React, { useState, useEffect, useCallback } from "react";
import { CDBDataTable } from "cdbreact";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import PaginationComponent from "../componentes/Pagination";
import Badge from 'react-bootstrap/Badge';


 function GetQuiz() {
   const navigate = useNavigate();

  // Check if there's a token in local storage
  const token = localStorage.getItem("token");

  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }
  const { id } = useParams();
  const [scores, setScores] = useState([]);
  const [quiz, setQuiz] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState("");

  const getScores = useCallback(async () => {
    try {
      const response = await axios.get(`https://lms.gamal-abdelnasser.com/scores/${id}`, {
        params: { page, pageSize, search },
        headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
                  },

      });
      setScores(response.data.data.scores.scores);
      setQuiz(response.data.data.quizzes)
        setTotalRecords(response.data.data.scores.totalRecords);
      // Handle other response data like totalRecords, etc.
    } catch (error) {
      if (error.response.data.status_code === 401) {
        navigate("/login");
      }
      console.error("Error fetching courses:", error);
    }
  }, [page, pageSize, search , navigate , id]);

  useEffect(() => {
    // Fetch courses from the backend endpoint
    getScores();
  }, [getScores]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page on search
  };

  const columns = [
    { label: "#", field: "index" },
    { label: "اسم الطالب", field: "title" },
    { label: "الايميل", field: "email" },
    { label: "الدرجة ", field: "score" },
    { label: "الدرجة النهائية ", field: "fullmark" },
    { label: "نسبة النجاح", field: "prcent" },
    { label: "الحالة", field: "status" },
  ];

  const data = scores?.map((score, index) => ({
    index: index + 1,
    title:(<span>{score?.user?.firstName} {score?.user?.lastName}</span> ),
    email: score?.user?.email,

    score: score.score,
    fullmark: quiz?.Questions?.length,
    prcent: (<>{quiz?.passPercent }%</>),
    status: ( <>
    {score.quizPassed ?<Badge bg="success">ناجح</Badge> : <Badge bg="danger">راسب</Badge>}
    </>)
  }));

  const totalPages = Math.ceil(totalRecords / pageSize);
  return (
    <div>
        <h3 className="d-inline">
           درجات اختبار :
           <span className="d-inline-block ms-1 text-muted"> {quiz?.title} </span>
              </h3>
      <div className="mb-3 mt-3 w-100 d-flex justify-content-between align-items-center">
        <input
          type="text"
          placeholder="    ادخل ايميل الطالب "
          value={search}
          onChange={handleSearchChange}
          className="form-control saerch-input w-25"
        />
      </div>
      <CDBDataTable
        striped
        bordered
        hover
        data={{ columns, rows: data }}
        paging={false} 
        searching={false}
        checkbox
        sortable={true}
      />
      <PaginationComponent
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    
    </div>
  );
}

export default GetQuiz
