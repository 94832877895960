import React, { useState, useEffect, useCallback } from "react";
import { CDBDataTable } from "cdbreact";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaginationComponent from "../componentes/Pagination";


export const Courses = () => {
  const navigate = useNavigate();

  // Check if there's a token in local storage
  const token = localStorage.getItem("token");

  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }

  const [courses, setCourses] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState("");
  const handleDeleteCourse = async (id) => {
    axios
      .delete(`https://lms.gamal-abdelnasser.com/courses/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        toast.success("تم حذف  الكورس بنجاح ");
        getAllCourses();
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        toast.error("حدثت مشكلة من فضلك حاول مرة اخري ");
      });
  };

  const handleOff = async (id) => {
    axios
      .post(`https://lms.gamal-abdelnasser.com/courses/off/${id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        toast.success("تم ايقاف الكورس بنجاح ");
        getAllCourses();
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        toast.error("حدثت مشكلة من فضلك حاول مرة اخري ");
      });
  };

  const handleOn = async (id) => {
    axios
      .post(`https://lms.gamal-abdelnasser.com/courses/on/${id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        toast.success("تم تفعيل الكورس بنجاح ");
        getAllCourses();
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        toast.error("حدثت مشكلة من فضلك حاول مرة اخري ");
      });
  };

  const getAllCourses = useCallback(async () => {
    try {
      const response = await axios.get('https://lms.gamal-abdelnasser.com/courses/admin', {
        params: { page, pageSize, search },
        headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
                  },

      });
      setCourses(response.data.data.courses);
        setTotalRecords(response.data.data.totalRecords);
      // Handle other response data like totalRecords, etc.
    } catch (error) {
      if (error.response.data.status_code === 401) {
        navigate("/login");
      }
      console.error("Error fetching courses:", error);
    }
  }, [page, pageSize, search , navigate]);

  useEffect(() => {
    // Fetch courses from the backend endpoint
    getAllCourses();
  }, [getAllCourses]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page on search
  };

  const columns = [
    { label: "#", field: "index" },
    { label: "الاسم", field: "title" },
    { label: "الوصف", field: "description" },
    { label: "تاريخ البدء", field: "startDate" },
    { label: "تاريخ الانتهاء", field: "endDate" },
    { label: "السعر", field: "price" },
    { label: "الخصم", field: "discount" },
    { label: "اعدادات", field: "actions" },
  ];

  const data = courses?.map((course, index) => ({
    index: index + 1,
    title: (
      <>
        <img src={course.img} className="rounded me-2" width={70} alt="" /> {course.title}
      </>
    ),
    description:
      course.discreption.length > 70
        ? `${course.discreption.slice(0, 70)}...`
        : course.discreption,
    startDate: new Date(course.startDate)
      .toLocaleDateString("en-US")
      .replace(/\//g, "-"),
    endDate: new Date(course.endDate)
      .toLocaleDateString("en-US")
      .replace(/\//g, "-"),
    price: course.price,
    discount: course.discount,
    actions: (
      <div>
        <button
          className="btn btn-danger btn-sm m-auto ms-2 mt-2"
          onClick={() => handleDeleteCourse(course.id)}
        >
          حذف
        </button>
        {course.status === "on" && (
          <button
            className="btn btn-warning btn-sm m-auto ms-2 mt-2"
            onClick={() => handleOff(course.id)}
          >
            ايقاف الكورس
          </button>
        )}
        {course.status === "off" && (
          <button
            className="btn btn-warning btn-sm m-auto ms-2 mt-2"
            onClick={() => handleOn(course.id)}
          >
            تفعيل الكورس
          </button>
        )}
      </div>
    ),
  }));

  const totalPages = Math.ceil(totalRecords / pageSize);
  return (
    <div>
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <Link
          to="/courses/add"
          className="btn btn-primary border-0 ml-auto px-3 py-2 my-2 text-white text-sm"
          // style={{ background: "#596FB7" }}
        >
          <i className="fa fa-plus me-2"></i>
          اضافة كورس
        </Link>
        <input
          type="text"
          placeholder="ابحث باسم الكورس او الوصف "
          value={search}
          onChange={handleSearchChange}
          className="form-control saerch-input w-25"
        />
      </div>
      <CDBDataTable
        striped
        bordered
        hover
        data={{ columns, rows: data }}
        paging={false} 
        searching={false}
        checkbox
        sortable={true}
      />
      <PaginationComponent
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
     
    </div>
  );
};

