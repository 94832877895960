import React, { useEffect, useState } from "react";
import { CDBNavbar } from "cdbreact";

const Navbar = () => {
  const [head , setHead] =useState("")
  const pathname = window.location.pathname.substring(1); 

  useEffect(() => {
    const routing =   {
      courses : "الكورسات",
      sections : "الوحدات",
      vedioes : "الفيديوهات",
      quizzes : "الكويزات" ,
       enrolls : "طلبات الاشتراك",
      students : "الطلبة", 
      coursesadd : "الكورسات / اضافة كورس" ,
       scores: "الدرجات" ,
       quizAdd: "الكويزات / اضافة كويز"
  
    }
    if (pathname === "") {
      setHead("الرئيسية");
    } else if (pathname === "courses") {
      setHead(routing.courses);
    } else if (pathname === "sections") {
      setHead(routing.sections);
    } else if (pathname === "vedioes") {
      setHead(routing.vedioes);
    } else if (pathname === "quizzes") {
      setHead(routing.quizzes);
    } else if (pathname === "quiz/add") {
      setHead(routing.quizAdd);
    }else if (pathname === "enrolls") {
      setHead(routing.enrolls);
    } else if (pathname === "students") {
      setHead(routing.students);
    } else if (pathname === "courses/add") {
      setHead(routing.coursesadd);
    } else if (/^scores\/\d+$/.test(pathname)) { // Use a regex to match "scores/:id"
      setHead(routing.scores);
    } else {
      setHead("");
    }
  }, [pathname]);
  // {pathname.substring(1) == "" ? "الرئيسية" : pathname.substring(1)}
  
	return (
       
          <CDBNavbar dark expand="md" scrolling className="justify-content-start nav-head px-3">
            <h5 className="me-3 mb-0 py-2 ">
            {head}

            </h5>
           
          </CDBNavbar>
       
	);
}

export default Navbar;
