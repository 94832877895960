import React, { useState } from "react";
import "./Dashboard.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function CreateCourse() {
  const navigate = useNavigate();
  const [imgFile, setImgFile] = useState(null);
  // Check if there's a token in local storage
  const token = localStorage.getItem("token");

  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }
  const schema = yup.object().shape({
    title: yup.string().required("عنوان الكورس مطلوب"),
    discreption: yup.string().required("وصف الكورس مطلوب"),
    price: yup.number().required("السعر مطلوب"),
    startDate: yup.date().required("تاريخ البدء مطلوب"),
    endDate: yup.date().required("تاريخ الانتهاء مطلوب"),
    discount: yup.number().required("الخصم مطلوب"),
    img: yup.mixed().required("صورة الكورس مطلوبة "),
  });

  const handleFileChange = async (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    const base64String = await fileToBase64(file);
    console.log(base64String);
    setImgFile(base64String);
    setFieldValue("img", base64String);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const response = await axios.post(
        "https://lms.gamal-abdelnasser.com/courses",
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
          },
        }
      );
      console.log(response.data.data);
      toast.success("تم انشاء الكورس بنجاح ");
      resetForm();
      navigate("/courses");
    } catch (error) {
      if (error.response.data.status_code === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("حدثت مشكلة من فضلك حاول مرة أخري");
      }
      console.error("Error:", error);
    }
    setSubmitting(false);
  };
  return (
    <div className="createCourse w-100">
      <div className="form-container">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            title: "",
            discreption: "",
            price: "",
            startDate: "",
            endDate: "",
            discount: "",
            img: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} className="mb-3">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationFormik01">
                  <Form.Label>عنوان الكورس</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    // isValid={touched.title && !errors.title}
                    isValid={touched.title && !errors.title}
                    isInvalid={touched.title && !!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik03">
                  <Form.Label>السعر</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    isValid={touched.price && !errors.price}
                    isInvalid={touched.price && !!errors.price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.price}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationFormik07">
                  <Form.Label>الخصم</Form.Label>
                  <Form.Control
                    type="number"
                    name="discount"
                    value={values.discount}
                    onChange={handleChange}
                    isValid={touched.discount && !errors.discount}
                    isInvalid={touched.discount && !!errors.discount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.discount}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Add other form fields here */}
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationFormik04">
                  <Form.Label>تاريخ البدء</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={values.startDate}
                    onChange={handleChange}
                    isValid={touched.startDate && !errors.startDate}
                    isInvalid={touched.startDate && !!errors.startDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik05">
                  <Form.Label> تاريخ الانتهاء</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={values.endDate}
                    onChange={handleChange}
                    isValid={touched.endDate && !errors.endDate}
                    isInvalid={touched.endDate && !!errors.endDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.endDate}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="12"
                  className="mt-3"
                  controlId="validationFormik02"
                >
                  <Form.Label>الوصف</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="discreption"
                    value={values.discreption}
                    onChange={handleChange}
                    isValid={touched.discreption && !errors.discreption}
                    isInvalid={touched.discreption && !!errors.discreption}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.discreption}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="12"
                  className="mt-3"
                  controlId="validationFormik06"
                >
                  <Form.Label>صورة الكورس</Form.Label>
                  <Form.Control
                    type="file"
                    name="img"
                    id="img"
                    onChange={(event) => handleFileChange(event, setFieldValue)}
                    // isInvalid={!!errors.img}
                    isValid={touched.img && !errors.img}
                    isInvalid={touched.img && !!errors.img}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.img}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button className="w-200px mt-2" type="submit">
                اضافة
              </Button>
            </Form>
          )}
        </Formik>
        <div className="d-flex justify-content-center align-items-center img-placeholder rounded-4 h-75">
          {imgFile && (
            <img
              src={imgFile}
              alt="Preview"
              className="w-100 rounded-4 h-100"
            />
          )}{" "}
        </div>
      </div>
    </div>
  );
}

export default CreateCourse;
