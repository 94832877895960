import React, { useState, useEffect, useCallback } from "react";
import "./Dashboard.css";
import { CDBDataTable } from "cdbreact";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaginationComponent from "../componentes/Pagination";
function CreateSection() {
  const navigate = useNavigate();

  // Check if there's a token in local storage
  const token = localStorage.getItem("token");

  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }
  const [sections, setSections] = useState([]);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page on search
  };
  const getAllSections = useCallback(async () => {
    try {
      const response = await axios.get('https://lms.gamal-abdelnasser.com/sections/list', {
        params: { page, pageSize, search },
        headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
                  },

      });
      setSections(response.data.data.sections);
      setTotalRecords(response.data.data.totalRecords);
      // Handle other response data like totalRecords, etc.
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  }, [page, pageSize, search]);

  useEffect(() => {
    // Fetch courses from the backend endpoint

    getAllSections();
    // getAllCourses();
  }, [getAllSections]);

  // const getAllSections = () => {
  //   axios
  //     .get(`https://lms.gamal-abdelnasser.com/sections/list?page=${page}&pageSize=${pageSize}&search=${search}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
  //       },
  //     })
  //     .then((response) => {
  //       setSections(response.data.data.sections);
  //       setTotalRecords(response.data.data.totalRecords);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching sections:", error);
  //     });
  // };

  const schema = yup.object().shape({
    title: yup.string().required("عنوان الوحدة مطلوب"),
    courseId: yup.string().required("الكورس مطلوب"),
  });

  const handleDeletesection = async (id) => {
    try {
      // Submit the form data
      await axios.delete(`https://lms.gamal-abdelnasser.com/sections/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("تم المسح");
      getAllSections();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("حدثت مشكلة من فضلك حاول مرة أخري");
    }
  };

  const getAllCourses = async () => {
    axios
      .get("https://lms.gamal-abdelnasser.com/courses")
      .then((response) => {
        setCourses(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post("https://lms.gamal-abdelnasser.com/sections", values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("تم انشاء الوحدة بنجاح ");
      resetForm();
      getAllSections();
      console.log("Form submitted successfully:", values);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("حدثت مشكلة من فضلك حاول مرة أخري");
    }
    setSubmitting(false);
  };
  const totalPages = Math.ceil(totalRecords / pageSize);
  const columns = [
    {
        label: '#',
        field: 'index',
        sort: 'asc',
        width: 50,
    },
    {
        label: 'الاسم',
        field: 'title',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'الكورس',
        field: 'courseTitle',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'الاعدادات',
        field: 'actions',
        width: 100,
    },
];

const rows = sections.map((section, index) => ({
    index: index + 1,
    title: section.title,
    courseTitle: section.course.title,
    actions: (
        <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDeletesection(section.id)}
        >
            حذف
        </button>
    ),
}));
  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          title: "",
          courseId: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className=" border-bottom pb-3">
            <Row className="mb-3 align-items-center">
              <Form.Group as={Col} md="3" controlId="validationFormik05">
                <Form.Label>عنوان الوحدة</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  isValid={touched.title && !errors.title}
                  isInvalid={touched.title && !!errors.title}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationFormik04">
                <Form.Label>تابعة لكورس</Form.Label>
                <Form.Control
                  as="select"
                  name="courseId"
                  value={values.courseId}
                  onChange={handleChange}
                  onFocus={getAllCourses}
                  isValid={touched.courseId && !errors.courseId}
                  isInvalid={touched.courseId && !!errors.courseId}
                >
                  <option value="">اختر الكورس...</option>
                  {courses.map((course) => (
                    <option key={course.id} value={course.id}>
                      {course.title}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.courseId}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Add other form fields here */}
            </Row>
            <Row>
              <Form.Group as={Col} md="3" controlId="validationFormik03">
                <Form.Label></Form.Label>
                <Button type="submit" className="w-200px">
                  اضافة
                </Button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>

      <div className="mt-3">
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
      <h4>الوحدات </h4>
       
        <input
          type="text"
          placeholder="ابحث باسم الكورس"
          value={search}
          onChange={handleSearchChange}
          className="form-control saerch-input w-25"
        />
      </div>
      <CDBDataTable
            striped
            bordered
            hover
            data={{ columns, rows }}
            paging={false} 
            searching={false}
            checkbox
            sortable={true}
        />
          <PaginationComponent
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      </div>
    </div>
  );
}

export default CreateSection;
