import React, { useState, useEffect, useCallback } from "react";
import { CDBDataTable } from "cdbreact";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaginationComponent from "../componentes/Pagination";

function Enrolls() {
  const navigate = useNavigate();

  // Check if there's a token in local storage
  const token = localStorage.getItem("token");

  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }
  const [enrolls, setEnrolls] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState("");
  const handleActivate = async (id) => {
    await axios
      .post(`https://lms.gamal-abdelnasser.com/courses/activate/${id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      })
      .then(() => {
        toast.success("تم تفعيل الكورس بنجاح ");
        //   setEnrolls(response.data.data);
        getAllEnrolls();
        // Set the fetched courses to the state
      })
      .catch((error) => {
        toast.error("   حدثت مشكلة حاول مرة اخري  ");
        console.error("Error fetching courses:", error);
      });
  };

  const handleDeleteEnroll = async (id) => {
    await axios
      .delete(`https://lms.gamal-abdelnasser.com/enrolls/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      })
      .then((response) => {
        //   setEnrolls(response.data.data);
        toast.success("تم مسح الطلب ");
        getAllEnrolls();
        // Set the fetched courses to the state
      })
      .catch((error) => {
        toast.error("   حدثت مشكلة حاول مرة اخري  ");
        console.error("Error fetching courses:", error);
      });
  };

  const handleReject = async (id) => {
    await axios
      .post(`https://lms.gamal-abdelnasser.com/courses/reject/${id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      })
      .then((response) => {
        //   setEnrolls(response.data.data);
        toast.success("تم رفض الطلب ");
        getAllEnrolls();
        // Set the fetched courses to the state
      })
      .catch((error) => {
        toast.error("   حدثت مشكلة حاول مرة اخري  ");
        console.error("Error fetching courses:", error);
      });
  };

  const getAllEnrolls = useCallback(async () => {
    try {
      const response = await axios.get('https://lms.gamal-abdelnasser.com/enrolls', {
        params: { page, pageSize, search },
        headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
                  },

      });
      setEnrolls(response.data.data.enrolls);
      setTotalRecords(response.data.data.totalRecords)
      // Handle other response data like totalRecords, etc.
    } catch (error) {
      console.error("Error fetching enrollments:", error);
    }
  }, [page, pageSize, search]); // dependencies of getAllEnrolls
  useEffect(() => {
    // Fetch courses from the backend endpoint
    getAllEnrolls();
  }, [getAllEnrolls]);

  // const getAllEnrolls = async () => {
  //   await axios
  //     .get(
  //       `https://lms.gamal-abdelnasser.com/enrolls?page=${page}&pageSize=${pageSize}&search=${search}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setEnrolls(response.data.data.enrolls);
  //       setTotalRecords(response.data.data.totalRecords); // Set the fetched courses to the state
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching courses:", error);
  //     });
  // };

  const columns = [
    { label: "#", field: "index" },
    { label: "عنوان الكورس", field: "courseTitle" },
    { label: "اسم الطالب", field: "firstName" },
    { label: "ايميل الطالب", field: "email" },
    { label: "اعدادات", field: "actions" },
  ];

  const rows = enrolls.map((enroll, index) => ({
    index,
    courseTitle: enroll.course.title,
    firstName: enroll.user.firstName,
    email: enroll.user.email,
    actions: (
      <>
        {enroll.enrollment.status === "pending" ? (
          <>
            <button
              onClick={() => handleActivate(enroll.enrollment.id)}
              className="btn me-2 btn-sm btn-primary"
            >
              تفعيل الكورس
            </button>
            <button
              onClick={() => handleReject(enroll.enrollment.id)}
              className="btn btn-sm btn-danger"
            >
              {" "}
              رفض الطلب
            </button>
          </>
        ) : (
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              handleDeleteEnroll(enroll.enrollment.id);
            }}
          >
            {" "}
            حذف الاشتراك
          </button>
        )}
      </>
    ), // include more fields if needed
  }));

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page on search
  };

  const totalPages = Math.ceil(totalRecords / pageSize);
  return (
    <div>
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <h4>جميع الطلبات </h4>
        <input
          type="text"
          placeholder="ابحث باسم الكورس او اسم الطالب او الايميل"
          value={search}
          onChange={handleSearchChange}
          className="form-control saerch-input w-25"
        />
      </div>
      <CDBDataTable
        striped
        bordered
        hover
        data={{ columns, rows }}
        paging={false}
        searching={false}
        checkbox
        sortable={true}
      />
      <PaginationComponent
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

    
    </div>
  );
}

export default Enrolls;
