// CreateQuizPage.js
import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";


const validationSchema = yup.object().shape({
  title: yup.string().required("عنوان الامتحان مطلوب"),
  passPercent: yup.number().required(" مطلوب"),
  videoId: yup.string().required(" اختر الفيديو"),
  questions: yup.array().of(
    yup.object().shape({
      content: yup.string().required("السؤال مطلوب"),
      choices: yup.array().of(
        yup.object().shape({
          content: yup.string().required("الاختيار مطلوب"),
          isCorrect: yup.boolean(),
        })
      ),
    })
  ),
});

const initialValues = {
  title: "",
  passPercent: "",
  videoId: "",
  questions: [{ content: "", choices: [{ content: "", isCorrect: false }] }],
};

const CreateQuizPage = () => {
  const [videoes, setVideoes] = useState(null);
  const getAllVedioes = useCallback(async () => {
    try {
      const response = await axios.get("https://lms.gamal-abdelnasser.com/vedioesList", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      });

      console.log(response.data.data);
      setVideoes(response.data.data);
    } catch (error) {
      if (error.response.data.status_code === 400) {
        toast.error(error.response.data.message)
      }
      console.error("Error fetching videoes:", error.response.data.status_code);
    }
  }, []);

  useEffect(() => {
    getAllVedioes();
  }, [getAllVedioes]);

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        "https://lms.gamal-abdelnasser.com/quizzes",
        values
      );
      console.log("Quiz created successfully:", response.data);
      // Navigate("/quizzes");
      toast.success("تمت اضافة الامتحان");
    } catch (error) {
      toast.error(error.response.data.status_code === 400 ? error.response.data.message : "حدثت مشكلة " )
      console.error("Error creating quiz:", error);
    }
  };

  return (
    <div className="w-50 m-auto border rounded p-3 px-4">
      <h2>اضافة كويز</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form>
            <div className="row">
              <div className="col col-md-5">
                <label htmlFor="title" className="mb-2">
                  عنوان الامتحان
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  className="form-control"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="error text-danger text-sm"
                />
              </div>
              <div className="col col-md-5">
                <label htmlFor="videoId" className="mb-2">
                  اختر الفيديو{" "}
                </label>
                <Field
                  as="select"
                  id="videoId"
                  name="videoId"
                  className="form-control"
                >
                  <option value="" className="text-sm">
                    اختر فيديو...
                  </option>
                  {videoes?.map((video) => (
                    <option className="text-sm" key={video.id} value={video.id}>
                      {video.title}
                    </option>
                  ))}
                </Field>

                <ErrorMessage
                  name="videoId"
                  component="div"
                  className="error text-danger text-sm"
                />
              </div>
              <div className="col col-md-2">
                <label htmlFor="passPercent" className="mb-2">
                  نسبة النجاح{" "}
                </label>
                <Field
                  type="number"
                  id="passPercent"
                  name="passPercent"
                  className="form-control"
                />
                <ErrorMessage
                  name="passPercent"
                  component="div"
                  className="error text-danger text-sm"
                />
              </div>
            </div>

            <FieldArray name="questions">
              {({ push, remove }) => (
                <div className="mt-2 rounded">
                  <label className="mb-2">الاسئلة :</label>
                  {values.questions.map((question, index) => (
                    <div
                      key={index}
                      className="border p-3 rounded mb-4 position-relative"
                    >
                      <Field
                        type="text"
                        name={`questions[${index}].content`}
                        placeholder={`سؤال  ${index + 1}`}
                        className="form-control"
                      />
                      <ErrorMessage
                        name={`questions[${index}].content`}
                        component="div"
                        className="error text-danger text-sm"
                      />

                      <FieldArray name={`questions[${index}].choices`}>
                        {({ push, remove }) => (
                          <div className="">
                            {question.choices.map((choice, choiceIndex) => (
                              <>
                                <div
                                  key={choiceIndex}
                                  className="d-flex justify-content-between align-items-center mt-2"
                                >
                                  <div>
                                    <Field
                                      type="text"
                                      name={`questions[${index}].choices[${choiceIndex}].content`}
                                      placeholder={`اختيار ${choiceIndex + 1}`}
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`questions[${index}].choices[${choiceIndex}].content`}
                                      component="div"
                                      className="error text-danger text-sm"
                                    />
                                  </div>
                                  <div>
                                    <label
                                      htmlFor={`questions[${index}].choices[${choiceIndex}].isCorrect`}
                                    >
                                      <Field
                                        type="checkbox"
                                        id={`questions[${index}].choices[${choiceIndex}].isCorrect`}
                                        name={`questions[${index}].choices[${choiceIndex}].isCorrect`}
                                        className="form-check-inline me-1"
                                      />
                                      الاجابة الصحيحة
                                    </label>

                                    <button
                                      type="button"
                                      onClick={() => remove(choiceIndex)}
                                      disabled={question.choices.length === 1}
                                      hidden={question.choices.length === 1}
                                      className="btn btn-sm btn-danger ms-2 me-0 rounded btn-trash rounded-circle"
                                    >
                                      <i className="fa fa-trash fa-xs align-middle"></i>
                                    </button>
                                  </div>
                                </div>
                              </>
                            ))}

                            <button
                              type="button"
                              className="btn btn-sm border-0 me-auto mt-2 btn-outline-primary"
                              onClick={() =>
                                push({ content: "", isCorrect: false })
                              }
                            >
                              <i className="fa fa-plus fa-sm me-1"></i>
                              اضافة اختيار
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      <button
                        type="button"
                        onClick={() => remove(index)}
                        disabled={values.questions.length === 1}
                        hidden={values.questions.length === 1}
                        className="btn btn-sm btn-danger ms-2 me-0 position-absolute rounded qs-trash btn-trash rounded-circle"
                      >
                        <i className="fa fa-trash fa-xs align-middle"></i>
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary border-0"
                    onClick={() =>
                      push({
                        content: "",
                        choices: [{ content: "", isCorrect: false }],
                      })
                    }
                  >
                    <i className="fa fa-plus fa-sm me-1"></i>
                    اضافة سؤال
                  </button>
                </div>
              )}
            </FieldArray>

            <button
              type="submit"
              className="btn btn-sm btn-primary w-200px mt-2"
            >
              اضافة
            </button>
          </Form>
        )}
      </Formik>
    
      <div>
    
      </div>

    </div>
  );
};

export default CreateQuizPage;
