import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { CDBDataTable } from "cdbreact";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PaginationComponent from "../componentes/Pagination";

function Students() {
  const schema = yup.object().shape({
    password: yup.string().required("كلمة السر مطلوبة  "),
    confirm_password: yup.string().required("كلمة السر مطلوبة "),
  });
  const [userId, setUserId] = useState(null);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // Submit the form data
      await axios.post(`https://lms.gamal-abdelnasser.com/reset/${userId}`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Form submitted successfully:", values);
      toast.success("تم تعديل كلمة السر  ");
      // localStorage.setItem("token" , res.data.data.token)
      resetForm();
      handleClose();
      // navigate("/")
    } catch (error) {
      if (error.response.data.status_code === 400) {
        // setErr(error.response.data.message)
      } else {
        toast.error("حدثت مشكلة حاول مرة اخري ");
      }
      console.error("Error submitting form:", error);
    }
  };

  const [show, setShow] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setUserId(id);
  };
  const navigate = useNavigate();

  // Check if there's a token in local storage
  const token = localStorage.getItem("token");

  // If there's no token, render the login page
  if (!token) {
    navigate("/login");
  }
  const [users, setUsers] = useState([]);
  const getAllUsers = useCallback(async () => {
    try {
      const response = await axios.get("https://lms.gamal-abdelnasser.com/users", {
        params: { page, pageSize, search },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the request header
        },
      });
      setUsers(response.data.data.users);
      setTotalRecords(response.data.data.totalRecords);
      // Handle other response data like totalRecords, etc.
    } catch (error) {
      console.error("Error fetching enrollments:", error);
    }
  }, [page, pageSize, search]);
  useEffect(() => {
    // Fetch courses from the backend endpoint
    getAllUsers();
  }, [getAllUsers]);

  const handleDeleteUser = async (id) => {
    axios
      .delete(`https://lms.gamal-abdelnasser.com/users/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //   setUsers(response.data.data);
        toast.success("تم المسح بنجاح");
        getAllUsers(); // Set the fetched courses to the state
      })
      .catch((error) => {
        toast.error("حدثت مشكلة حاول مرة اخري   ");
        console.error("Error fetching courses:", error);
      });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page on search
  };

  const totalPages = Math.ceil(totalRecords / pageSize);
  const columns = [
    {
      label: "#",
      field: "index",
    },
    {
      label: "الاسم",
      field: "name",
    },
    {
      label: "الايميل",
      field: "email",
    },
    {
      label: "التليفون",
      field: "phone",
    },
    {
      label: "تليفون الاب",
      field: "parentPhone",
    },
    {
      label: "المحافظة ",
      field: "gover",
    },
    {
      label: "الصف",
      field: "class",
    },
    {
      label: "اعدادات",
      field: "actions",
    },
  ];

  // Prepare data rows
  const data = users.map((user, index) => ({
    index: index + 1,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    phone: user.phone,
    parentPhone: user.parentPhone,
    gover: user.gover,
    class: user.class,
    actions: (
      <>
        <button
          className="btn btn-danger btn-sm ms-2"
          onClick={() => handleDeleteUser(user.id)}
        >
          حذف
        </button>
        <Button
          variant="warning"
          className="btn-sm"
          onClick={() => handleShow(user.id)}
        >
          تعديل كلمة السر
        </Button>
      </>
    ),
  }));

  return (
    <div>
     
        <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
          <h4>جميع الطلبة </h4>
          <input
            type="text"
            placeholder=" ابحث باسم الطالب او الايميل"
            value={search}
            onChange={handleSearchChange}
            className="form-control saerch-input w-25"
          />
        </div>
        <CDBDataTable
          striped
          bordered
          hover
          data={{ columns, rows: data }}
          paging={false}
          searching={false}
          checkbox
          sortable={true}
        />
        <PaginationComponent
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
    

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل كلمة السر</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{
              password: "",
              confirm_password: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3 align-items-center">
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    md="12"
                    controlId="validationFormik05"
                  >
                    <Form.Label> كلمة السر الجديدة</Form.Label>
                    <Form.Control
                      type="password"
                      //   className='form__input'
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isValid={touched.password && !errors.password}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormik07">
                    <Form.Label>تاكيد كلمة السر الجديدة </Form.Label>
                    <Form.Control
                      type="password"
                      name="confirm_password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      isValid={
                        touched.confirm_password && !errors.confirm_password
                      }
                      isInvalid={
                        touched.confirm_password && !!errors.confirm_password
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirm_password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* {err? <>
<span className='text-danger '>{err}</span>
</>: ""} */}
                  <Form.Group as={Col} md="12" controlId="validationFormik03">
                    <Form.Label> </Form.Label>
                    <Button type="submit" className="mt-4 w-100">
                      تعديل
                    </Button>
                  </Form.Group>
                  {/* Add other form fields here */}
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
       
      </Modal>

    </div>
  );
}

export default Students;
