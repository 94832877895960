import React, { useState } from 'react'
import "./login.css"
import { Formik } from "formik";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate  } from "react-router-dom";
function Login() {
  
const [err , setErr] = useState(null)
const navigate = useNavigate()
    const schema = yup.object().shape({
        email: yup.string().email(' ايميل غير صحيح').required("الايميل مطلوب"),
        password: yup.string().required("كلمة السر مطلوبة "),
      });

      const handleSubmit = async (values , { setSubmitting, resetForm , setFieldValue  }) => {
        try {
          // Submit the form data
          const res = await axios.post("https://lms.gamal-abdelnasser.com/admin/login", values);
          console.log("Form submitted successfully:", values);
          toast.success("تم تسجيل الدخول بنجاح ")
          localStorage.setItem("token" , res.data.data.token)
          resetForm();
          navigate("/")
        } catch (error) {
            if(error.response.data.status_code === 400){
                setErr(error.response.data.message)
            }else{
                toast.error("حدثت مشكلة حاول مرة اخري ")
            }
          console.error("Error submitting form:", error);
        }
      };
  return (
    
    <div className='container-fluid box'>

    <div class="_container">
    <div class="form" id="login">
      <h1 class="form__title">Welcome back</h1>
      <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={{
                  email: "",
                  password: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3 align-items-center">
                      <Form.Group
                      className='mb-3'
                        as={Col}
                        md="12"
                        controlId="validationFormik05"
                      >
                        {/* <Form.Label>عنوان الوحدة</Form.Label> */}
                        <Form.Control
                          type="email"
                        //   className='form__input'
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          isInvalid={touched.email && !!errors.email}
                        />
                      <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik07"
                      >
                        {/* <Form.Label>عنوان الوحدة</Form.Label> */}
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isValid={touched.password && !errors.password}
                          isInvalid={touched.password && !!errors.password}
                        />
                       <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
{err? <>
<span className='text-danger '>{err}</span>
</>: ""}
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik03"
                      >
                        <Form.Label> </Form.Label>
                        <Button type="submit" className="mt-4 w-100">
                          اضافة
                        </Button>
                      </Form.Group>
                      {/* Add other form fields here */}
                    </Row>
                  </Form>
                )}
              </Formik>
     
    </div>
  </div>
  </div>
  )
}

export default Login
